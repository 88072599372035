const Footer = () => {
    return (
        <div className='text-container'>
            <p className='text-sm text-center mt-8'>© 2024 noahmartineau.com. All rights reserved.</p>
        </div>
    )
}

export default Footer;

